<template>
    <div>
        <v-tabs v-model="tab" show-arrows height="40" ref="tabs">
            <v-tab  v-if="!menu.rights || $func.hasRight(menu.rights)" exact :to="{name : menu.name, query: (menu.query ? menu.query : false)}" v-for="(menu, index) in subRoutes" :key="index" @click="callSlider" class="tabSubMenu" :ref="'tab'+index" :replace="true">
                {{$t(menu.title)}}
            </v-tab>
        </v-tabs>
    </div>
</template>
<script>
export default {
    name:"SubMenu",
    props: ['subRoutes'],
    data() {
        return {
            interval: "",
            tab: ""
        }
    },
    watch: {
        tab(newVal){
        }
    },
    created() {
        if(this.$refs && this.$refs.tabs)
            this.$refs.tabs.callSlider()

            if(this.$refs && this.$refs.tabs)
        setTimeout(() => {
            this.tab = this.$route.path
            this.$nextTick(() =>{
                this.callSlider();
            })
            
        }, 200);
    },
    mounted() {
        //Interval pour régler le bug css sur la border rouge des sous menu
        if(this.$refs && this.$refs.tabs)
            this.$refs.tabs.callSlider()
     
        setTimeout(() => {
            this.tab = this.$route.path
            this.$nextTick(() =>{
                this.callSlider();
            })
        }, 200);
        // if(this.$refs.tab0){
        //     const initial = this.$refs.tab0[0].$el.offsetWidth;
        //     this.interval = setInterval(() => {
        //     if (this.$refs.tab0[0]) {
        //         if (this.$refs.tab0[0].$el.offsetWidth !== initial) {
        //             clearInterval(this.interval)
        //             this.$refs.tabs.callSlider()
        //         }
        //     } else {
        //         clearInterval(this.interval)
        //     }
        //     }, 1000)
        // } else {
        //     clearInterval(this.interval)
        // }
    },
    beforeDestroy() {
        // clearInterval(this.interval)
    },
    methods: {
        callSlider: function(){
            if(this.$refs && this.$refs.tabs)
                this.$refs.tabs.callSlider()
        }
    },
}
</script>
<style lang="scss">
    // .tabSubMenu {
    //     font-size:16px !important;
    // }
</style>
