import { render, staticRenderFns } from "./SubMenu.vue?vue&type=template&id=07ca5e53&"
import script from "./SubMenu.vue?vue&type=script&lang=js&"
export * from "./SubMenu.vue?vue&type=script&lang=js&"
import style0 from "./SubMenu.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabs})
